.tabs {
    width: 100%;
}

.tab-list {
    display: flex;
    border-bottom: 1px solid #ccc;
}

.tab-list-item {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    color: #000; /* Couleur du texte */
}

.tab-list-item:hover {
    background-color: #f0f0f0;
}

.tab-list-item.active {
    border-bottom: 2px solid #007bff;
    font-weight: bold;
    background-color: #007bff; /* Couleur de fond de l'onglet actif */
    color: #fff; /* Couleur du texte de l'onglet actif */
}

.tab-content {
    padding: 20px;
}