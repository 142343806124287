.stats-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.stats-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.stat-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    flex: 1 1 calc(33.333% - 20px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.stat-card h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
}

.stat-card p {
    font-size: 1.5em;
    color: #555;
}

@media (max-width: 768px) {
    .stat-card {
        flex: 1 1 calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .stat-card {
        flex: 1 1 100%;
    }
}

.stats-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.stats-table th, .stats-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.stats-table th {
    background-color: #f2f2f2;
    color: #333;
}

.stats-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.stats-table tr:hover {
    background-color: #ddd;
}

.stats-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #4CAF50;
    color: white;
}

.card {
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card h4, .card h6 {
    margin-bottom: 20px;
}

.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
}

table th, table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
}

table th {
    background-color: #90a4ae;
    color: #333;
}

table td {
    background-color: #f9f9f9;
    color: #333;
}

table tr:nth-child(even) {
    background-color: #f1f1f1;
}

table tr:hover {
    background-color: #ddd;
}
