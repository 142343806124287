* {
    box-sizing: border-box;
}
.series-details-container {
    margin: 20px;
    max-width: 100%;
    overflow-x: hidden;
    padding: 20px;
}
.series-summary, .series-bets-list {
    background-color: white;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.series-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.series-summary .series-name h2 {
    margin: 0;
    font-size: 1.5rem;
}
.series-bets-list {
    width: 100%;
    margin-top: 20px;
}
.series-split-series {
    margin: 10px 0;
    padding: 10px;
    background: none;
    border: none;
    box-shadow: none;
}
.series-split-series {
    display: flex;
    justify-content: center;
}
.series-bets-list table {
    width: 100%;
    border-collapse: collapse;
}
.series-bets-list th, .series-bets-list td {
    padding: 6px;
    text-align: center;
    font-size: 0.9rem;
    word-wrap: break-word;
}
.series-bets-list th {
    background-color: #90a4ae;
    color: white;
}
.series-bets-list tr:nth-child(even) {
    background-color: #f9f9f9;
}
.series-bets-list tr:hover {
    background-color: #f1f1f1;
}
.series-bets-list th.bet-id-col, .series-bets-list td.bet-id-col {
    width: 5%;
}
.series-bets-list th.team-col, .series-bets-list td.team-col {
    width: 18%;
}
.series-bets-list th.bookmaker-col, .series-bets-list td.bookmaker-col {
    width: 13%;
}
.series-bets-list th.cote-col, .series-bets-list td.cote-col {
    width: 7%;
}
.series-bets-list th.profit-col, .series-bets-list td.profit-col {
    width: 7%;
}
.series-bets-list th.stake-col, .series-bets-list td.stake-col {
    width: 7%;
}
.series-bets-list th.date-col, .series-bets-list td.date-col {
    width: 10%;
}
.series-bets-list th.actions-col, .series-bets-list td.actions-col {
    width: 25%;
}
.series-bets-list .actions-col button {
    background: none;
    border: none;
    padding: 2px;
    margin: 0 2px;
    cursor: pointer;
}
.series-bets-list .actions-col button:hover {
    background-color: #e0e0e0;
}
.edit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 10px;
}
.edit-button:hover {
    background-color: #45a049;
}
.edit-icon {
    cursor: pointer;
    margin-left: 10px;
    color: #4CAF50;
}
.edit-icon:hover {
    color: #45a049;
}

.series-info {
    margin-bottom: 20px;
}

.table-container {
    overflow-x: auto; /* Permet le défilement horizontal */
}

table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
}

table th, table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: center;
}

table th {
    background-color: #90a4ae; /* Header avec couleur pastel #90a4ae */
    color: #333; /* Changement de la couleur du texte à #333 pour une meilleure lisibilité */
}

table td {
    background-color: #f9f9f9;
    color: #333;
}

table tr:nth-child(even) {
    background-color: #f1f1f1;
}

table tr:hover {
    background-color: #ddd;
}