.investors-container {
    padding: 20px;
}

.add-investor-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.add-investor-form input, .add-investor-form button {
    padding: 10px;
    margin-right: 10px;
}

.add-investor-form button {
    background-color: #6a11cb;
    color: white;
    border: none;
    cursor: pointer;
}

.add-investor-form button:hover {
    background-color: #2575fc;
}

.investors-list {
    list-style: none;
    padding: 0;
}

.investor-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2b2b3c;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.investor-link {
    color: white;
    text-decoration: none;
}

.investor-link:hover {
    text-decoration: underline;
}

.delete-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
}

.delete-button:hover {
    background-color: #c0392b;
}
