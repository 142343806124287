.goals-tracker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    color: black;
}

.goals-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
}

.goal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    background-color: #2b2b3c;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.goal.achieved {
    background-color: #4caf50;
}

.goal input {
    flex: 1;
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    border: none;
}

.goal button {
    background-color: #000;
    color: white;
    border: none;
    padding: 5px 10px;
    margin-left: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.goal button:hover {
    background-color: #bdbdbd;
}

.add-goal {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.add-goal input {
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    width: 200px;
    text-align: center;
}

.add-goal button {
    background-color: #000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.add-goal button:hover {
    background-color: #bdbdbd;
}

.total-profit {
    margin-top: 20px;
    font-size: 1.5em;
    text-align: center;
}
