/* Styles for the buttons */
.filter-button {
    background-color: #fff;
    border: 1px solid #bdbdbd;
    color: black;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s, color 0.3s;
}

.filter-button:hover {
    background-color: #bdbdbd;
}

.filter-button.active {
    background-color: #bdbdbd;
    color: white;
}

/* General styling */
.table-container {
    position: relative;
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

thead tr {
    position: sticky;
    top: 0;
    background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
    color: black;
}

th, td {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

th {
    z-index: 1; /* Ensure the header is above other content */
}

.card-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items at the start */
    margin-bottom: 20px;
}

.card, .bankroll-info {
    flex: 1;
    margin: 0 10px;
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    max-width: 50%;
    box-sizing: border-box;
}

.card h2, .bankroll-info h2 {
    margin-top: 0;
    font-weight: 600;
}

.bankroll-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bankroll-input-container {
    display: flex;
    align-items: center;
}

.bankroll-info label {
    margin-right: 10px;
}

.bankroll-info input {
    background-color: #fff;
    border: 1px solid #bdbdbd;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
}

.bankroll-info p {
    margin-top: 10px;
    font-size: 1.2em;
}

/* Styling for the editable series name */
.series-name {
    display: inline-flex;
    align-items: center;
}

.edit-icon {
    margin-left: 8px;
    cursor: pointer;
}
