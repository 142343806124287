/* frontend/src/components/HistoricalSeries.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    background-color: #f4f4f9; /* Couleur de fond plus claire */
    color: #333; /* Couleur de texte plus sombre */
}

.historical-series-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    max-width: 100%;
}

h1 {
    text-align: center;
    color: #000;
    font-size: 1.8rem;
    margin-bottom: 20px;
}

.card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 1200px;
}

.filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
}

.date-filter, .sport-filter {
    display: flex;
    align-items: center;
}

.date-filter label, .sport-filter label {
    margin-right: 20px;
    font-size: 1rem;
}

table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
}

table th, table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: center;
}

table th {
    background-color: #90a4ae; /* Header avec couleur pastel #90a4ae */
    color: #333; /* Changement de la couleur du texte à #333 pour une meilleure lisibilité */
}

table td {
    background-color: #f9f9f9;
    color: #333;
}

table tr:nth-child(even) {
    background-color: #f1f1f1;
}

table tr:hover {
    background-color: #ddd;
}

input[type="checkbox"] {
    transform: scale(1.2);
}

table th button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    margin-left: 5px;
    color: #333;
}

table th button:hover {
    color: #000;
}

@media (max-width: 768px) {
    .card {
        padding: 10px;
    }

    table th, table td {
        padding: 5px;
        font-size: 0.75rem;
    }
}
