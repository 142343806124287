.investor-details-container {
    margin: 20px;
}

.investor-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 20px;
}

.investor-summary div {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    flex: 1 1 45%;
    margin-bottom: 10px;
}

.investor-summary .capital {
    background-color: #e0f7fa;
}

.investor-summary .profit {
    background-color: #e8f5e9;
}

.investor-card {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.investments-table, .bets-list {
    width: 100%;
    border-collapse: collapse;
}

.investments-table th, .bets-list th, .investments-table td, .bets-list td {
    padding: 5px;  /* Reduced padding for all table cells */
    border: 1px solid #ddd;
    text-align: center;
}

.bets-list tr:nth-child(even), .investments-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.bets-list th, .investments-table th {
    background-color: #90a4ae;
    color: white;
}

.bets-list tr:hover, .investments-table tr:hover {
    background-color: #f1f1f1;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 0.8rem;
    margin-left: 5px;
}

button:hover {
    color: #333;
}

.chart-container {
    position: relative;
    height: 300px; /* Ajustez la hauteur selon vos besoins */
    width: 100%;
}

@media (max-width: 768px) {
    .investor-summary {
        flex-direction: column;
    }

    .investor-summary div {
        font-size: 1.2rem;
        padding: 10px;
        flex: 1 1 100%;
    }

    .investments-table th, .investments-table td, .bets-list th, .bets-list td {
        padding: 8px;
        font-size: 0.9rem;
    }

    .bets-list th, .bets-list td {
        padding: 5px;
        font-size: 0.8rem;
    }

    .bets-list th {
        white-space: nowrap;
    }

    .bets-list td, .investments-table td {
        word-wrap: break-word;
    }

    .bets-list, .investments-table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .investments-table th, .investments-table td {
        padding: 5px;
        font-size: 0.8rem;
    }

    .investments-table th {
        white-space: nowrap;
    }

    .investments-table td {
        word-wrap: break-word;
    }
}
