body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #fafafa;
  color: #000;
}

a {
  color: black;
  text-decoration: underline;
}

a:hover {
  color: #ccc;
  text-decoration: underline;
}

.app {
  text-align: center;
}

.app-header {
  background-color: #fff;
  padding: 20px;
  font-size: 24px;
  color: #000;
}

.app-nav ul {
  list-style-type: none;
  padding: 0;
  background-color: white;
  margin-block-end: 0;
  margin-block-start: 0;
}

.app-nav ul li {
  display: inline;
  margin: 0 10px;
}

.app-nav ul li a {
  color: #000;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
}

.app-nav ul li a:hover {
  background-color: #e6e6e6;
}

.app-main {
  padding: 20px;
}

.card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}

button {
  background-color: #171717;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: inherit;
}

button:hover {
  background-color: #383838;
}

input, select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  background-color: #fff;
  color: #000;
  margin: 10px 0;
  font-family: inherit;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  padding: 10px;
  padding-top:5px;
  padding-bottom:5px;
  border: 1px solid #bdbdbd;
  border-right: 0px;
  border-left:0px
}

table th {
  background-color: #fafafa;
}

table td {
  background-color: #fff;
}


@media (max-width: 768px) {
  .app-nav ul li {
      display: block;
      margin: 10px 0;
  }
}

