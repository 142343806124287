.logs-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.logs-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.logs-table th, .logs-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.logs-table th {
    background-color: #f2f2f2;
    color: #333;
}

.logs-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.logs-table tr:hover {
    background-color: #ddd;
}

.logs-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #4CAF50;
    color: white;
}

.team-row {
    background-color: #e0e0e0;
    cursor: pointer;
}

.toggle-button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.toggle-button:hover {
    background-color: #45a049;
}

button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}